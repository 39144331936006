<template>

  <div>

    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>


    <template v-else>

      <!-- empty state: no existen datos-->
      <template v-if="isEmptyList">

        <b-card class="text-center">

          <feather-icon
              icon="InboxIcon"
              size="100"
              class="mr-50 mb-5"
          />
          <h2>No hay cargos para mostrar</h2>
          <p class="p-2">
            Actualmente no hay cargos para mostrar. <br>
          </p>


        </b-card>

      </template>

      <!-- sección de datos -->
      <template v-else>

    <b-card>


      <b-row>

        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <label>Mostrar</label>
          <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
          />
          <label>Entradas</label>
        </b-col>
        <!-- Buscar -->
        <b-col
            cols="12"
            md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
            />


          </div>
        </b-col>


      </b-row>


      <div class="m-2">

        <b-table
            class="position-relative"
            :items="filteredData"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
        >

          <!-- Nombre Cargo -->
          <template #cell(posNombre)="data">


            <b-button
              variant="link"
              class="p-0"
              @click="handleEdit( data.item.posId )"
            >

              <span>
                {{ data.item.posNombre }}

              </span>

            </b-button>

          </template>

          <template #cell(posEstado)="data">
            <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.item.posEstado)}`"
                class="text-capitalize"
            >
              {{ resolveNameStatus(data.item.posEstado) }}
            </b-badge>
          </template>

        </b-table>


      </div>


      <!-- pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ totalRecords }} registros encontrados</span>
          </b-col>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>

      </div>


    </b-card>

      </template>
    </template>


    <!-- disable animation-->
    <b-modal
        content-class="shadow"
        title="Modificar cargo"
        no-close-on-backdrop
        v-model="showModal"
        centered

    >

      <b-form-group
          label="Descripción"
          label-for="cargo"
      >
        <b-form-input
            id="cargo"
            maxlength="500"
            autofocus
            trim
            autocomplete="off"
            v-model="cargoLocal.posNombre"
        />

      </b-form-group>

      <template #modal-footer>
        <div class="w-100 text-right">

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="isBusy || hasSomeNullEmptyItem"
              @click.prevent="handleSubmit"
          >
            <template v-if="isBusy">
              <b-spinner small/>
              Guardando
            </template>
            <template v-else>
              Guardar
            </template>
          </b-button>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="handleDiscard"
          >
            Descartar
          </b-button>

        </div>
      </template>


    </b-modal>

  </div>

</template>

<script>

import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge, BPagination, BModal, BAlert, BFormGroup, BSpinner

} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { resolvePerPageOptions } from '@/modules/common/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CargosList'
  ,
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BModal,
    BAlert,
    BFormGroup,
    BSpinner,
    vSelect
  },

  directives: {
    Ripple,
  },

  data(){
    return {

      currentPage: 1,
      totalRecords: 0,
      perPageOptions: resolvePerPageOptions(),
      perPage: 10,
      searchQuery: '',
      showModal: false,
      isBusy: false,
      selectedCargo: null,
      isLoading: false,

      fields: [
        { key: 'posNombre', label: 'Nombre', sortable: true },
        { key: 'posEstado', label: 'Estado', sortable: true },
      ],

      cargoLocal:{ },

      items: []

    }
  },

  computed:{
    ...mapGetters('sga-module', ['fetchCargosFilter']),

    hasSomeNullEmptyItem(){
      return this.cargoLocal.posNombre === null || this.cargoLocal.posNombre === ''
    },

    filteredData(){
      const data = this.fetchCargosFilter( this.searchQuery )
      this.totalRecords = data.length
      return data
    },

    // verificar que existan datos originales (antes de filtros)
    isEmptyList(){
      return this.fetchCargosFilter().length === 0
    }

  },

  methods: {

    ...mapActions('sga-module', ['getCargos', 'findOneId', 'updateCargo']),
    resolveStatusVariant(estado){

      if ( estado == "1" ) return "success"
      return "secondary"
    },


    async loadData(){

      await this.getCargos( )

      this.items = this.fetchCargosFilter()
    },

    handleDiscard(){
      this.showModal = false
    },

    async handleSubmit(){

      try {

        this.errors = []

        this.isBusy = true

        const params = {

          posUuid: this.cargoLocal.posUuid,
          posNombre: this.cargoLocal.posNombre

        }

        await this.updateCargo( params )

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sistema',
            icon: 'CheckIcon',
            text: '¡Cargo actualizado exitosamente!',
            variant: 'success'
          },
        })

        this.showModal = false

        await this.loadData()

      } catch ( error ) {

        // errores de autenticacion
        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }


      } finally {
        this.isBusy = false
      }

    },



    resolveNameStatus(estado){
      if ( estado == "1" ) return "activo"
      return "inactivo"
    },

   async  handleEdit( id ){

      this.cargoLocal = await this.findOneId( { posId:id } )

      this.showModal = true
    }

  },

  async created() {

    await this.loadData()
  }

}
</script>

<style scoped>

</style>
