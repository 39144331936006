<template>

  <div>

    <cargos-list/>

  </div>


</template>

<script>

import { BButton, BCard, BCol, BFormInput, BRow, BTable } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CargosList from '@/modules/ejecutante/views/catalogos/cargos/CargosList.vue'

export default {
  name: 'Cargos',
  data(){
    return {
      perPageOptions: [10,25,50,100],
      perPage: null,
      searchQuery: null,

    }
  },
  components: {
    CargosList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,


    vSelect

  }
}
</script>

<style scoped>

</style>
